import React, { useEffect, useRef } from 'react';
import { Chat, Speed, Sync } from '@mui/icons-material';
import telegramIcon from './images/telegram.png';

const Dddmarket = () => {
  const containersRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.remove('opacity-0', 'translate-y-4');
          entry.target.classList.add('opacity-100', 'translate-y-0');
        } else {
          entry.target.classList.remove('opacity-100', 'translate-y-0');
          entry.target.classList.add('opacity-0', 'translate-y-4');
        }
      });
    }, {
      threshold: 0.1,
      rootMargin: '0px 0px -50% 0px'
    });

    containersRef.current.forEach(container => {
      if (container) {
        observer.observe(container);
      }
    });

    return () => {
      containersRef.current.forEach(container => {
        if (container) {
          observer.unobserve(container);
        }
      });
    };
  }, []);

  // Градиенты для каждого блока
  const gradientStyles = [
    { background: 'radial-gradient(ellipse at left, #1137c6 10%, #0d0d0d 70%, #0d0d0d 15%)' }, // Первый блок
    { background: 'radial-gradient(ellipse at center, #00ff85 10%, #0d0d0d 70%, #0d0d0d 15%)' }, // Второй блок
    {
      background: 'radial-gradient(ellipse at center, #DD637C 10%, #0d0d0d 70%, #0d0d0d 15%)',
      backgroundSize: '50% 100%' // Третий блок с дополнительным свойством
    },
  ];

  return (
    <section className="mt-60 sm:mt-60 md:mt-28 lg:mt-36">
      <div className='container h-full md:h-192 flex flex-col'>
        <div className='h-auto md:h-1/2 flex flex-col md:flex-row gap-2'>
          {/* Блок про быстрый отклик через чатботы */}
          <div
            ref={el => containersRef.current[0] = el}
            className="h-96 md:h-96 px-4 py-4 rounded-md w-full md:w-1/3 relative opacity-0 translate-y-4 transition-all duration-500 ease-in-out"
            style={gradientStyles[0]}
          >
            <h2 className="text-2xl 2xl:text-5xl font-semibold text-white">
              быстрый отклик<br /> на запросы
            </h2>
            <p className="mt-4 text-xl 2xl:text-3xl text-white">
              telegram чатботы позволяют мгновенно реагировать на запросы клиентов, повышая их удовлетворенность
            </p>
            <Chat className="absolute bottom-4 left-4" fontSize="inherit" style={{ color: '#ffffff', fontSize: '48px' }} />
          </div>

          {/* Блок про автоматизацию процессов */}
          <div
            ref={el => containersRef.current[1] = el}
            className="h-96 md:h-96 px-4 py-4 rounded-md w-full md:w-1/3 relative opacity-0 translate-y-4 transition-all duration-500 ease-in-out"
            style={gradientStyles[1]}
          >
            <h2 className="text-2xl 2xl:text-5xl font-semibold text-white">
              автоматизация<br /> бизнес-процессов
            </h2>
            <p className="mt-4 text-xl 2xl:text-3xl text-white">
              чатботы упрощают обработку заказов, ведение клиентов и интеграцию с CRM, освобождая время сотрудников
            </p>
            <Sync className="absolute bottom-4 left-4" fontSize="inherit" style={{ color: '#ffffff', fontSize: '48px' }} />
          </div>

          {/* Блок про конкурентное преимущество */}
          <div
            ref={el => containersRef.current[2] = el}
            className="h-96 md:h-96 px-4 py-4 rounded-md w-full md:w-1/3 relative opacity-0 translate-y-4 transition-all duration-500 ease-in-out"
            style={gradientStyles[2]}
          >
            <h2 className="text-2xl 2xl:text-5xl font-semibold text-white">
              преимущество<br /> на рынке
            </h2>
            <p className="mt-4 text-xl 2xl:text-3xl text-white">
              использование чатботов дает вам преимущество, ускоряя процессы и улучшая взаимодействие с клиентами
            </p>
            <Speed className="absolute bottom-4 left-4" fontSize="inherit" style={{ color: '#ffffff', fontSize: '48px' }} />
          </div>
        </div>

        {/* Узкие плашки с видами чатботов */}
        <div className='h-auto md:h-1/2 flex flex-col md:flex-row mt-2 gap-2'>
          <div className="w-full h-72 md:h-full border-2 border-white md:w-1/3 text-white p-2 rounded-md">
            <div className='flex flex-col justify-between h-full'>
              <h3 className="text-2xl 2xl:text-4xl text-success font-semibold">чатботы для продаж</h3>
              <p className='mt-8 text-gray-300 text-xl 2xl:text-3xl'>автоматизация продаж, обработка заказов и консультирование клиентов в режиме реального времени</p>
            </div>
            {/* <div className='flex justify-end mt-4'>
            <img src={telegramIcon} alt="Telegram Icon" className='w-10 h-10' />
            </div> */}
          </div>
          <div className="w-full h-72 md:h-full md:w-1/3 border-2 border-white text-white p-2 rounded-md">
            <div className='flex flex-col justify-between h-full'>
              <h3 className="text-2xl 2xl:text-4xl text-success font-semibold">служба поддержки</h3>
              <p className='mt-8 text-gray-300 text-xl 2xl:text-3xl'>чатботы для поддержки клиентов, обработки запросов и решения вопросов в любое время суток</p>
            </div>
            {/* <div className='flex justify-end mt-4'>
            <img src={telegramIcon} alt="Telegram Icon" className='w-10 h-10' />
            </div> */}
          </div>
          <div className="w-full h-72 md:h-full md:w-1/3 border-2 border-white text-white p-2 rounded-md">
            <div className='flex flex-col justify-between h-full'>
              <h3 className="text-2xl 2xl:text-4xl text-success font-semibold">маркетинговые чатботы</h3>
              <p className='mt-8 text-gray-300 text-xl 2xl:text-3xl'>автоматизированные рассылки, продвижение услуг и привлечение клиентов через мессенджеры</p>
              {/* <div className='flex justify-end mt-4'>
            <img src={telegramIcon} alt="Telegram Icon" className='w-10 h-10' />
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dddmarket;
